import * as msgpack from 'msgpack-lite';

export type DefaultObject = Record<string, unknown>;

export function encodeMSGPack<T = DefaultObject>(data: T): Uint8Array {
	return msgpack.encode(data);
}

export function decodeMSGPack<T = DefaultObject>(data: Uint8Array): T | undefined {
	try {
		return msgpack.decode(data);
	} catch (e) {
		return undefined as T;
	}
}

// type Join<K, P> = K extends string | number ? (P extends string | number ? `${K}${'' extends P ? '' : '.'}${P}` : never) : never;

// type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

// export type Paths<T, D extends number = 10> = [D] extends [never]
// 	? never
// 	: T extends object
// 		? {
// 				[K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
// 			}[keyof T]
// 		: '';

// type Split<S extends string, D extends string> = S extends `${infer T}${D}${infer U}` ? [T, ...Split<U, D>] : [S];

// type PathValue<T, P extends Array<string | number>> = P extends [infer K, ...infer Rest]
// 	? K extends keyof T
// 		? Rest extends Array<string | number>
// 			? PathValue<T[K], Rest>
// 			: T[K]
// 		: T extends Array<infer U>
// 			? K extends number
// 				? Rest extends Array<string | number>
// 					? PathValue<U, Rest>
// 					: U
// 				: never
// 			: never
// 	: T;

// Use these when TS is slow
export type Paths<T> = string;
export type PathValue<T, P extends string> = any;
export type Split<S extends string, D extends string> = string[];

export function getValue<T, P extends Paths<T>>(obj: T, path: P, defaultValue?: PathValue<T, Split<P, '.'>>): PathValue<T, Split<P, '.'>> | undefined {
	const keys = path.split('.');
	let result: any = obj;
	for (const key of keys) {
		if (result == null) {
			return defaultValue;
		}
		result = result[key];
	}
	return result === undefined ? defaultValue : result;
}

export function getFirstValue<T, P extends Paths<T>[]>(obj: T, paths: P): PathValue<T, Split<P[number], '.'>> | undefined {
	for (const path of paths) {
		const value = getValue(obj, path);
		if (isValidValue(value)) {
			return value;
		}
	}
	return undefined;
}

const isValidValue = (value: any) => value !== undefined && value !== null && value !== '';

export function getValueOrDefault<T, P extends Paths<T>>(obj: T, defaultValue: PathValue<T, Split<P, '.'>>, path: P): PathValue<T, Split<P, '.'>> {
	const value = getValue(obj, path, defaultValue);
	return value !== undefined ? value : defaultValue;
}

// Set value at path
export function setValue<T, P extends Paths<T>>(obj: T, path: P, value: PathValue<T, Split<P, '.'>>): void {
	const keys = path.split('.');
	let result: any = obj;
	for (let i = 0; i < keys.length - 1; i++) {
		const key = keys[i];
		if (result[key] == null) {
			result[key] = {};
		}
		result = result[key];
	}
	result[keys[keys.length - 1]] = value;
}
