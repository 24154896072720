import { ActionType, MailingAddress, PointsEventType, Store } from '@/types';
import { Typography } from '@alpine-iq/ui-library';
import { capitalize } from 'lodash';
import React from 'react';
import utils from './utils';

export function cleanString(str: string) {
	if (typeof str === 'string') return str.toString().replace(/\s+/g, '').toLowerCase().trim();
	if (React.isValidElement(str)) return str;
	if (Array.isArray(str) || (typeof str === 'object' && str !== null)) return JSON.stringify(str || {});
	return str || '';
}

// Clean any input
export function clean(input: any) {
	if (typeof input === 'string') return cleanString(input);
	return input;
}

export function addressToString(
	address: Partial<MailingAddress> | MailingAddress | undefined,
	options?: {
		useRaw?: boolean;
		useLatLong?: boolean;
		useCountry?: boolean;
		clean?: boolean;
	},
): string {
	if (!address) return '';

	const { street, city, state, zipCode, country, lat, long, raw } = address;
	const { useRaw, useLatLong, useCountry, clean = true } = options || {};

	if (raw && useRaw) return raw;
	if (lat && long && useLatLong) return `${lat},${long}`;
	if (country && useCountry) return country;

	const formatAddressPart = (part: string | undefined): string => {
		if (!part) return '';
		return part
			.split(' ')
			.map((word) => {
				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
			})
			.join(' ');
	};

	const formattedStreet = clean ? formatAddressPart(street) : street || '';
	const formattedCity = clean ? formatAddressPart(city) : city || '';
	const formattedState = clean ? formatAddressPart(state) : state || '';

	return `${formattedStreet}, ${formattedCity}, ${formattedState} ${zipCode || ''}`.trim();
}

export function cleanPhone(phone: string | undefined): string {
	if (!phone) return '';
	return phone?.trim().replaceAll('+', '');
}

export function cleanEmail(email: string | undefined): string {
	if (!email) return '';
	return email.trim().toLowerCase();
}

export function getFormattedPointsEventType(eventType: PointsEventType, isBlock?: boolean): string {
	if (isBlock) return 'Block Adjustment';
	switch (eventType) {
		case 'point_freeze_end':
			return 'Point Freeze End';
		case 'point_freeze_start':
			return 'Point Freeze Start';
		default:
			return capitalize(eventType);
	}
}

export function getEmployeeTitle(employee: { id: string; name: string }) {
	if (employee.name.length > 3) return employee.name;
	return `${employee.name} (${employee.id})`;
}

export function getFormattedStoreName(store: Partial<Store>): string {
	return `${store.nickname || store.name} (${[store.addr?.city, store.addr?.state].join(', ')})`;
}

export function getFormattedStoreNameForSelect(store: Partial<Store> | undefined): React.ReactNode {
	if (!store) return null;
	return (
		<Typography>
			{store.nickname || store.name} {store.addr ? <Typography variant="body-sm">({[store.addr?.city, store.addr?.state].join(', ')})</Typography> : null}
		</Typography>
	);
}

export function getFullName(firstName: string | undefined, lastName: string | undefined): string {
	return [firstName, lastName]
		.filter((name) => name)
		.map((name) => capitalize(name))
		.join(' ');
}

export function formatPhoneNumber(phone: string | undefined): string {
	if (!phone) return '';
	const cleanedPhone = cleanPhone(phone);

	if (cleanedPhone.length < 10) {
		return '';
	}

	const nationalNumber = cleanedPhone.slice(-10);
	const countryCode = cleanedPhone.slice(0, -10);

	if (countryCode) {
		return `+${countryCode} (${nationalNumber.slice(0, 3)}) ${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6)}`;
	} else {
		return `(${nationalNumber.slice(0, 3)}) ${nationalNumber.slice(3, 6)}-${nationalNumber.slice(6)}`;
	}
}

// Input can be a phone.. email.., or name
export function formatSender(input: string | undefined): string {
	if (!input) return '';
	if (utils.phoneRex.test(input)) return formatPhoneNumber(input);
	if (utils.emailRE.test(input)) return cleanEmail(input);
	return capitalize(input);
}

type ActionConfig = {
	defaultEnabled: boolean;
	name: string;
	description: string;
	template: string | undefined;
};
export const ConversionActionMap: Record<ActionType, ActionConfig> = {
	text_received: {
		defaultEnabled: true,
		name: 'Text received',
		description: 'After a text is sent',
		template: 'templates',
	},
	text_click_to_open: {
		defaultEnabled: true,
		name: 'Opened text',
		description: 'After they open a text',
		template: 'templates',
	},
	text_sent: {
		defaultEnabled: true,
		name: 'Customer response via text',
		description: 'After customer responds to a text',
		template: 'templates',
	},
	text_click: {
		defaultEnabled: true,
		name: 'Landing page viewed',
		description: 'After landing page link was clicked',
		template: 'templates',
	},
	email_received: {
		defaultEnabled: true,
		name: 'Email received',
		description: 'After email is sent',
		template: 'emailTemplate',
	},
	email_open: {
		defaultEnabled: true,
		name: 'Opened email',
		description: 'After an email was opened',
		template: 'emailTemplate',
	},
	push_received: {
		defaultEnabled: true,
		name: 'Native push received',
		description: 'After native app push delivered',
		template: 'pushTemplate',
	},
	push_open: {
		defaultEnabled: true,
		name: 'Opened native push',
		description: 'After a push was opened',
		template: 'pushTemplate',
	},
	ad_received: {
		defaultEnabled: false,
		name: 'Screen ad received',
		description: 'After an app ad or screen ad was shown',
		template: 'adTemplate',
	},
	browser_received: {
		defaultEnabled: true,
		name: 'Browser push received',
		description: 'After browser push delivered',
		template: 'browserTemplate',
	},
	direct_mail_received: {
		defaultEnabled: true,
		name: 'Direct mail received',
		description: 'After a direct mail letter was delievered',
		template: 'snailMailTemplate',
	},
	TerpliReview: {
		defaultEnabled: false,
		name: 'Terpli review left',
		description: 'After a Terpli review',
		template: undefined,
	},
	TerpliRecommendation: {
		defaultEnabled: false,
		name: 'Terpli recommendation left',
		description: 'After a Terpli recommendation',
		template: undefined,
	},
	TerpliReviewDiscount: {
		defaultEnabled: false,
		name: 'Terpli review discount',
		description: 'After a Terpli review discount',
		template: undefined,
	},
	TerpliRecommendationDiscount: {
		defaultEnabled: false,
		name: 'Terpli recommendation discount',
		description: 'After a Terpli recommendation discount',
		template: undefined,
	},
	call_answer: {
		defaultEnabled: true,
		name: 'Voice drop answered',
		description: 'After call was answered by a person',
		template: 'voiceTemplate',
	},
	call_vm: {
		defaultEnabled: true,
		name: 'Voice drop hit voicemail',
		description: 'After call went to voicemail',
		template: 'voiceTemplate',
	},
	call_complete: {
		defaultEnabled: true,
		name: 'Voice drop completed',
		description: 'After a voice drop is completed',
		template: 'voiceTemplate',
	},
	discount_redemption: {
		defaultEnabled: true,
		name: 'Discount redeemed',
		description: 'After a discount is redeemed',
		template: 'discountTemplate',
	},
} as const;

/**
 * Returns a formatted title for a given action. (Conversion action)
 *
 * @param {ActionType} action - The action type to format.
 * @returns {string} The formatted action title.
 */
export function getFormattedActionTitle(action: ActionType): string {
	return ConversionActionMap[action]?.name || capitalize(action?.replace(/_/g, ' '));
}
export function getFormattedAddress(address: Partial<MailingAddress>) {
	if (!address) return;

	const { street, city, state, zipCode } = address;
	const presentAC = [street, city, state].filter((comp) => comp);

	const joined = presentAC.join(', ');
	if (zipCode) return [joined, zipCode].join(' ');

	return joined;
}
