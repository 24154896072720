/* eslint-disable no-tabs */
/* eslint-disable camelcase */
import decode from 'jwt-decode';
import moment from 'moment';

import { AccountMessage } from '@/legacy-types';
import { Role } from '@/legacy-types';
import { ImpliedConsent, User } from '@/legacy-types';
import userUtils from '../userUtils';
import utils from '../utils';

import PermissionsService from './permissions';

interface TokenWrappedConfig {
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
	token?: string;
	payload?: any;
	args?: any[];
	opts?: any;
}
type FetchOptions = {
	method?: string;
	payload?: any;
	opts?: any;
	signal?: any;
	forceToken?: string;
	fullResponse?: boolean;
	timeout?: number;
	retries?: number;
	retryDelay?: number;
	enableCaching?: boolean;
	[other: string]: any;
};

export default class AuthService {
	currentUser: User | null;
	initialUser: User | null;
	permissions: PermissionsService;

	// Initializing important variables
	constructor() {
		this.currentUser = null;
		this.initialUser = null;

		this.permissions = PermissionsService.getInstance();
	}

	// { user, token }
	signup = async (data: any) => {
		const resp = await this.legacyFetchWrap(`/signup`, 'POST', data);

		this.setToken(resp.access_token); // Setting the token in localStorage
		this.setUID(resp.uid);
		this.setEmail(resp.email);
		this.setRole(resp.role);
		return resp.uid;
	};

	editSubUser = async (uid: string, login: any) => await this.legacyFetchWrap(`/users/sub/${uid}`, 'PUT', login);
	addSubUser = async (uid: string, login: any) => await this.legacyFetchWrap(`/users/sub/${uid}`, 'POST', login);
	enableSubUser = async (uid: string, email: string, status: boolean) =>
		await this.legacyFetchWrap(`/users/sub/enable/${uid}/${encodeURIComponent(email)}/${status}`, 'PUT');

	signupFromAgencyOrAdmin = async (user: User) => {
		utils.trimObject(user);
		return await this.legacyFetchWrap(`/p/signup`, 'POST', { user });
	};

	login = async (resp: { access_token: string; uid: string; role: Role; email: string; globalID: string }) => {
		// if it errors it won't get this far
		sessionStorage.removeItem('refCode'); // unconditionally remove it
		sessionStorage.removeItem('fppc'); // unconditionally remove it
		this.setToken(resp.access_token); // Setting the token in localStorage
		this.setUID(resp.uid);
		this.setGlobalID(resp.globalID);
		this.setRole(resp.role);
		this.setEmail(resp.email);
		let user = undefined;
		try {
			user = await utils.auth.getUser(resp.uid);
			const perms = await this.permissions.getPerms(resp.uid);
			await this.permissions.setPerms(perms);
		} catch (err) {
			console.error(err);
		}
		return [resp.uid, resp.role, user];
	};

	loginPass = async (email: string, password: string) => {
		email = email?.toLocaleLowerCase()?.trim();
		// Get a token from api server using the fetch api
		const resp = await this.legacyFetchWrap(`/login`, 'POST', {
			email,
			password,
		});
		return await this.login(resp);
	};

	loginSso = async (sso: any, data: any) => {
		const resp = await this.legacyFetchWrap(`/sso/${sso}`, 'POST', data);
		return await this.login(resp);
	};

	//* Get admin audit stats (pts adjustments, etc)
	getAdminAuditStats = async (uid?: any, start?: any, end?: any) => {
		let url = `/adminAuditStats`;
		if (uid) url = `${url}/${uid}`;
		if (start && end) url = `${url}?start=${start}&end=${end}`;
		return this.legacyFetchWrap(url);
	};

	getUser = async (uid?: any) => {
		const u: User | undefined = await this.legacyFetchWrap(`/users/${uid || this.getUID()}`);
		if (!u) throw new Error('User not found');

		if (!this.initialUser) this.initialUser = u;

		this.currentUser = u;

		// Set the user in state at some point

		return u;
	};

	editUser = async (user: Partial<User> & { uid?: string; planOverride?: any }) => {
		const uid = user.uid || user.id;
		let ogUser: any = {};
		try {
			ogUser = await this.getUser(uid);
		} catch (err) {
			utils.showErr(err);
		}
		const payload: Partial<User> = {
			...ogUser,
			...user,
			profile: {
				...ogUser?.profile,
				...user.profile,
			},
			address: {
				...ogUser?.address,
				city: user.address?.city,
				country: user.address?.country,
				state: user.address?.state,
				zip: user.address?.zip,
			},
			id: uid,
		};
		utils.trimObject(payload);
		if (user.planOverride) user.planOverride = undefined;
		return this.legacyFetchWrap(`/users/${user.agencyID || ogUser.agencyID || ogUser?.type === 'agency' ? uid : ''}/${uid}`, 'PUT', payload);
	};

	updateUser = async (user: User) => {
		// @ts-ignore
		if (user.planOverride) user.planOverride = undefined;

		if (!utils.isAdmin()) {
			// ! Non admin users cannot update these values
			const ogUser = await this.getUser(user.id);

			user.referredBy = ogUser?.referredBy;
			user.salesRep = ogUser?.salesRep;
			user.disableWalledGarden = ogUser?.disableWalledGarden;
			user.disableBlockAdjustments = ogUser?.disableBlockAdjustments;
			user.enableRelativeExpirationEarn = ogUser?.enableRelativeExpirationEarn;
			user.disableAnonLoyalty = ogUser?.disableAnonLoyalty;
			user.optOutBounces = ogUser?.optOutBounces;
			user.requirePhoneCaptcha = ogUser?.requirePhoneCaptcha;
			user.message2FA = ogUser?.message2FA;
		}

		return this.legacyFetchWrap(`/users/${user.agencyID}/${user.id}`, 'PUT', { ...user });
	};

	setUserProbation = async (uid: string, status: any) => this.legacyFetchWrap(`/users/probation/${uid}/${status}`, 'PUT');

	// prettier-ignore
	createAutomation = async (name: string, targets: {}, likeValue: any, commentValue: any, messageValue: any, followValue: any, comments: any, messages: any, startTime: any, endTime: string, uid: string, type: string, channel: string, autoID: any, blockBots: any, nurtureLikes: any, nurtureDM: any, blockNegative: any, includeTags: any) => {
		const url = autoID ? `/automations/${uid}/${autoID}` : `/automations/${uid}`;
		let automationName = name || `${utils.capitalize(type)} Targeting - ${moment().format('DD/MM/YYYY HH:mm:ss')}`;
		let hashTargets = (targets ? targets : null);
		let locTargets = (targets ? targets : null);
		let searchTargets: any = targets;
		let competitors = (targets ? targets : null);
		let likeVal = (likeValue ? true : false);
		let commentVal = (commentValue ? true : false);
		let messageVal = (messageValue ? true : false);
		let followVal = (followValue ? true : false);
		let eventStartTime = (startTime ? startTime : '');
		let eventEndTime = (endTime ? endTime : '');
		let merge = {};

		if (type === 'hashtag' || type === 'event') {
			hashTargets = targets;
			locTargets = null;
			searchTargets = null;
			competitors = null;
		} else if (type === 'custom') {
			hashTargets = null;
			searchTargets = null;
			locTargets = null;
			competitors = null;
			merge = targets;
		} else if (type === 'location') {
			hashTargets = null;
			searchTargets = null;
			locTargets = targets;
			competitors = null;
		} else if (type === 'competitor') {
			hashTargets = null;
			searchTargets = null;
			locTargets = null;
			competitors = targets;
		}

		return this.legacyFetchWrap(url, autoID ? 'PUT' : 'POST', {
			userID: uid,
			name: automationName,
			isActive: true,
			automationType: type,
			channel: (channel || 'instagram').toLowerCase(),
			blockBots,
			nurtureLikes,
			nurtureDM,
			blockNegative,
			platform: {
				hashtags: hashTargets && {
					tag: hashTargets,
					start: eventStartTime ? eventStartTime : '',
					end: eventEndTime ? eventEndTime : ''
				},
				locations: searchTargets,
				coords: [],
				externalIDs: locTargets,
				competitors: competitors,
				comments: comments,
				messages: messages,
				shouldLike: likeVal,
				shouldComment: commentVal,
				shouldMessage: messageVal,
				shouldFollow: followVal,
				includeTags,
				...merge
			}
		});
	}

	/**
	 * @deprecated Replaced with fetchAPI from index/api.ts
	 */
	tokenWrapped = async (url?: string, config?: TokenWrappedConfig) => {
		const token = config?.token === undefined ? '' : config?.token;
		if (!token || !utils?.isLocal) return this.legacyFetchWrap(`/${url}`, ...(config?.args || [config?.method || 'GET', config?.payload, config?.opts]));
		return this.legacyFetch(`https://lab.alpineiq.com/api/v1/${url?.replace('api/v1', '')}`, {
			...config,
			...config?.args,
			forceToken: utils.isLocal() || utils.isStaging() ? token : undefined,
		});
	};

	getWallet = async (uid: any, contactID: any, storeID = '-1', config?: TokenWrappedConfig) => {
		const wallet = await this.tokenWrapped(`/getWalletForContactID/${uid}/${contactID}`, { token: utils.TOKEN });
		wallet.style = (await this.tokenWrapped(`settings/${uid}/${wallet.favoriteStoreID || storeID}?merge=true`, { token: utils.TOKEN })) || {};
		if (wallet.style?.toggles?.allowCustomStore) wallet.stores = await this.getPubStoreLocations(uid, { token: utils.TOKEN });
		return wallet;
	};

	getWalletByPhone = async (uid: any, phone: any, storeID = '-1') => {
		const wallet = await this.legacyFetchWrap(`/searchWallet/byPhone/${uid}?phone=${phone}`);
		wallet.style = await this.tokenWrapped(`settings/${uid}/${wallet.favoriteStoreID || storeID}?merge=true`, { token: utils.TOKEN });
		if (wallet.style?.toggles?.allowCustomStore) wallet.stores = await this.getPubStoreLocations(uid, { token: utils.TOKEN });
		return wallet;
	};

	getWalletBySrcID = async (uid: string, srcID: string, storeID = '-1', config?: TokenWrappedConfig) => {
		const wallet = await this.tokenWrapped(`/searchWallet/bySrcID/${uid}?srcID=${srcID}`, config);
		wallet.style = await this.tokenWrapped(`settings/${uid}/${wallet.favoriteStoreID || storeID}?merge=true`, config);
		if (wallet.style?.toggles?.allowCustomStore) wallet.stores = await this.getPubStoreLocations(uid, config);
		return wallet;
	};

	searchWallet = async (uid: any, kw: any, storeID = '-1', config?: TokenWrappedConfig) => {
		const wallet = await this.tokenWrapped(`/searchWallet/${uid}?kw=${kw}`, config);
		wallet.style = await this.tokenWrapped(`settings/${uid}/${wallet.favoriteStoreID || storeID}?merge=true`, config);
		if (wallet.style?.toggles?.allowCustomStore) wallet.stores = await this.getPubStoreLocations(uid, config);
		return wallet;
	};
	getWalletLogin = async (uid: any, phone: string) => this.legacyFetchWrap(`/requestWallet/${uid}/${phone}`);
	getWalletSeed = async () => this.legacyFetchWrap(`/walletSeed`);

	reqResetPass = async (email: any) => this.legacyFetchWrap(`/reqResetPassword`, 'POST', { email });
	resetPass = async (token: any, password: any) => this.legacyFetchWrap(`/resetPassword`, 'POST', { token, password });

	getBrandLifecycleData = async (uid: any, days: number) => this.tokenWrapped(`/leakyBrandControl/${uid}/${days}`, { token: utils.TOKEN });
	getLifecycleData = async (uid = utils.uid, days: number) => this.tokenWrapped(`/leakyControl/${uid}/${days}`, { token: utils.TOKEN });

	// updateLoyalty = async (uid: any, tmplID: any, data: any) => this.fetch(`/loyalty/${uid}${tmplID ? `/${tmplID}` : ''}`, 'POST', data)
	updateLoyalty = async (uid: string, tmplID: any, data: any) => {
		if (!tmplID) return this.legacyFetchWrap(`/loyalty/${uid}`, 'POST', data);
		return this.legacyFetchWrap(`/loyalty/${uid}/${tmplID}`, 'PUT', data);
	};
	getContactLoyaltyStats = async (uid: any, contactID: any, templateID: any) => this.legacyFetchWrap(`/loyalty/contact/${uid}/${contactID}/${templateID}`);
	getLoyaltyStats = async (uid: string, templateID: any) => this.legacyFetchWrap(`/loyalty/stats/${uid}/${templateID}`);
	getLoyaltyTemplates = async (uid: string, config?: TokenWrappedConfig) => {
		const ll = await this.tokenWrapped(`/loyalty/${uid}`, config);
		return (ll || []).sort((a: { id: string }, b: { id: string }) => parseInt(a.id) - parseInt(b.id));
	};

	getUserSettings = async (uid: string, storeID: string | number | undefined, config?: TokenWrappedConfig) =>
		await this.tokenWrapped(`settings/${uid}/${storeID === undefined ? '-1' : storeID}?merge=true`, config);

	stats = async (uid: string) => await this.legacyFetchWrap(`/stats/${uid}?posts=15`);
	targetStats = async (uid: string) => this.legacyFetchWrap(`/targets/stats/${uid}`);
	getReviewWidget = async (uid: string, contactID: string | undefined) => this.legacyFetchWrap(`/reviewWidget/${uid}/${contactID}`);
	getEmailValidationStatus = async (uid: string) => this.legacyFetchWrap(`/emailDomainCheck/${uid}`);
	adminRefReport = async (start: any, end: any) => this.legacyFetchWrap(`/referReport/${start}/${end}`);

	adminAllActiveUserStores = async () => this.legacyFetchWrap(`/allActiveUserStores`);
	// downloadUploadedCSV = async (key: any) =>  this.fetch(`/users/files/${utils.uid}/${key}`);
	downloadUploadedCSV = async (key: string, preview: any) => this.legacyFetchWrap(`/users/files/${utils.uid}/${key}/${preview}`);

	getGraphData = async (id: string) => this.legacyFetchWrap(`/insights/graph/${id}`);
	getAuditItems = async () => this.legacyFetchWrap(`/getCampaignApprovals`);
	getCoverPhoto = async (uid: string) => this.legacyFetchWrap(`/cover/${uid}`);
	getBrandName = async (uid: any) => this.legacyFetchWrap(`/username/${uid}`);

	dataRequest = async (uid: any, email?: string, phone?: string) => this.legacyFetchWrap(`/anon/request/${uid}`, 'POST', { phone, email }); // compliance ccpa/ gdpr stuff here
	dataConfirmLink = (uid: any, reqKey: any) => `/anon/confirm/${uid}/${reqKey}`;
	budOptOut = async (uid: any, phone: any, cntID: any) => this.put(`manual/optout/${uid}/${phone || '-1'}/${cntID}`);
	optOutRequest = async (uid: any, phone?: string, email?: string) => this.legacyFetchWrap(`/optout/request/${uid}`, 'POST', { phone, email, platforms: [-1] });
	optOutConfirm = async (uid: any, reqKey: any) => this.legacyFetchWrap(`/optout/confirm/${uid}/${reqKey}`);

	getBrandDiscovery = async (uid: string, data: any) => this.legacyFetchWrap(`/brand/discovery/${uid}`, 'POST', data);
	sendBrandInvite = async (uid: string, email: string) => this.legacyFetchWrap(`/inviteBrand/${uid}`, 'POST', { email });

	getEmailSender = async (uid: any, domain: any) => this.legacyFetchWrap(`/emailDomainAuthSender/${uid}/${domain}`); // email sender

	adminEmailDomainAuth = async (uid: any) => this.legacyFetchWrap(`/emailDomainAuth`); // get all currently authed domains across all clients
	emailDomainAuthAll = async (uid: string) => this.legacyFetchWrap(`/emailDomainAuth/${uid}`); // get all users authed domain
	emailDomainAuth = async (uid: any, domain: any) => this.legacyFetchWrap(`/emailDomainAuth/${uid}/${domain}`); // get users authed domain
	verifyEmailDomainAuth = async (uid: any, domain: any) => this.legacyFetchWrap(`/emailDomainAuthValidate/${uid}/${domain}`); // verify domain dns has been set correctly to specs given
	warningsEmailDomainAuth = async (uid: any, domain: any) => this.legacyFetchWrap(`/emailDomainWarnings/${uid}/${domain}`); // get errors for domains or warnings

	setBlockBots = async (uid: any, status: any, type = 'instagram') => this.legacyFetchWrap(`/blockBots/${uid}/${status ? 'true' : 'false'}/${type}`, 'PUT');
	setInboxStatus = async (uid: any, status: any) => this.legacyFetchWrap(`/inbox/status/${uid}/${status ? 'true' : 'false'}`, 'PUT');
	unlink = async (uid: any, type = 'instagram') => this.legacyFetchWrap(`/${type}/${uid}`, 'DELETE');

	getUsername = async (uid: any) => this.legacyFetchWrap(`/users/${uid}`);
	setPodOptOut = async (uid: any, status: any) => this.legacyFetchWrap(`/podOptOut/${uid}/${status ? 'true' : 'false'}`, 'PUT');
	getInstaLocations = async (lat: any, lng: any, uid: any) => this.legacyFetchWrap(`/location/${uid}/${lat}/${lng}`);
	getInstaPlaces = async (search: any, uid: any) => this.legacyFetchWrap(`/coords/${uid}/${search}`);
	getTwitterLocations = async (lat: any, lng: any, uid: any) => this.legacyFetchWrap(`/twitter/location/${uid}/${lat}/${lng}`);
	getTwitterPlaces = async (search: any, uid: any) => this.legacyFetchWrap(`/twitter/coords/${uid}/${search}`);
	getPhrases = async (uid: string) => this.legacyFetchWrap(`/inbox/${uid}`);
	addPhrases = async (keywords: any[], uid: string) => this.legacyFetchWrap(`/inbox/keywords/${uid}`, 'PUT', { keywords });

	isHalted = async () => await this.legacyFetchWrap(`/halt`);
	setHalted = async (status: boolean) => this.legacyFetchWrap(`/halt/${status ? true : false}`, 'PUT');

	isCacheLoaded = async () => {
		let resp = false;
		await utils.asyncFetch(async () => {
			const r = await this.legacyFetchWrap(`${location.origin}/cacheLoaded`);
			if (r === 'OK') resp = true;
		});
		return resp;
	};
	setCacheLoaded = async (status: boolean) => this.legacyFetchWrap(`/debug/disableCacheLoad/${status ? true : false}`);

	linkUser = async (uid: any, agencyID: any) => this.legacyFetchWrap(`/link/${uid}/${agencyID}`, 'PUT');
	setOpt = async (uid: any, status: any) => this.legacyFetchWrap(`/opt/${uid}/${status}`, 'PUT');
	manuallyChangePlan = async (uid: any, planID: any) => this.legacyFetchWrap(`/manualPlan/${uid}/${planID}`, 'PUT');
	planOverride = async (uid: any, planID: any, value: any) => this.legacyFetchWrap(`/planOverride/${uid}/${planID}/${value}`, 'PUT');
	// getNotifications = async (uid: string) => this.fetch(`/alerts/${uid}`);
	getRankings = async (uid: any, contactID: any) => this.legacyFetchWrap(`/contact/rankings/${uid}/${contactID}`);
	getBlacklist = async (uid: any) => this.legacyFetchWrap(`/blacklist/${uid}/all`);
	deleteBlacklistUser = async (uid: any, username: any, channel: any) => this.legacyFetchWrap(`/blacklist/${uid}/${username}/${channel}`, 'DELETE');
	getUsersByAgency = async (uid: string, listArchived = false) => this.legacyFetchWrap(`/usersByAgency/${uid}${listArchived ? '?all=true' : ''}`);
	manageBlocks = async (uid: any) => this.legacyFetchWrap(`/block/dump/${uid}/-1/-1`);
	getAgencies = async () => this.legacyFetchWrap(`/agencies`);
	getAdminStats = async () => this.legacyFetchWrap(`/insights`);
	getMissionControl = async (uid: string) => this.tokenWrapped(`/missionControl/${uid}`, { token: utils.TOKEN });

	getMissionControlStats = async (uid: string, sandbox: boolean) =>
		this.tokenWrapped(`/missionControlStats/${uid}${sandbox ? '?sandbox=true' : ''}`, { token: utils.TOKEN });
	getRetailersSelling = async (uid: string) => this.legacyFetchWrap(`/retailersSelling/${uid}`);
	getRetailersNotSelling = async (uid: string) => this.legacyFetchWrap(`/retailersNotSelling/${uid}`);
	getRetailersTopConverting = async (uid: string) => this.tokenWrapped(`/retailersTopConverting/${uid}`, { token: utils.TOKEN });
	getRetailersAlmostOut = async (uid: string) => this.tokenWrapped(`/retailersAlmostOut/${uid}`, { token: utils.TOKEN });
	// V2 eps
	getRetailersSellingV2 = async (uid: string) => this.legacyFetchWrap(`/retailersSellingV2/${uid}`);
	getRetailersNotSellingV2 = async (uid: string) => this.tokenWrapped(`/retailersNotSellingV2/${uid}`, { token: utils.TOKEN });

	getAPIKey = async (uid: any) => this.legacyFetchWrap(`/apiKey/${uid}`);
	refreshAPIKey = async (uid: any) => this.legacyFetchWrap(`/apiKey/create/${uid}`, 'POST');

	// * Audiences
	getSingleAudience = async (uid: string, audienceID: string) => this.legacyFetchWrap(`/audience/${uid}/${audienceID}`);
	createAudience = async (uid: any, data: any) => (await this.legacyFetchWrap(`/audience/${uid}`, 'POST', data)).aid;
	editAudience = async (uid: any, audienceID: any, data: any) => this.legacyFetchWrap(`/audience/${uid}/${audienceID}`, 'PUT', data);
	createAudienceGroup = async (uid: any, data: any) => (await this.legacyFetchWrap(`/audience/group/${uid}`, 'POST', data)).agid;
	archiveAudienceGroup = async (uid: any, agid: any, status: any) => this.legacyFetchWrap(`/audience/group/${uid}/${agid}/${status}`, 'PUT');
	archiveAudience = async (uid: any, audienceID: any, status: any) => this.legacyFetchWrap(`/audience/${uid}/${audienceID}/${status}`, 'PUT');
	getAudiences = async (uid: string, gid = '', full = '') => this.legacyFetchWrap(`/audiences/${uid}${gid ? '/' + gid : ''}?full=${full}`);
	getAudienceGroups = async (uid: string) => this.legacyFetchWrap(`/audience/groups/${uid}`);
	getMultipleAudiences = async (uid: string, data: any) => {
		const queryParams: string[] = [];
		if (!!localStorage.getItem('aiq:job:enabled') && userUtils.isSuperAdmin()) queryParams.push('job=true');
		return this.tokenWrapped(`/audiences/insights/${uid}?${queryParams.join('&')}`, { method: 'POST', payload: data, token: utils.TOKEN });
	};

	// helper func to add job=true to query params if user is super admin
	toggleJob = () => {
		if (!userUtils.isSuperAdmin()) return;
		const job = localStorage.getItem('aiq:job:enabled');
		if (job) {
			localStorage.removeItem('aiq:job:enabled');
			console.log('Job disabled');
		} else {
			localStorage.setItem('aiq:job:enabled', 'true');
			console.log('Job enabled');
		}
	};

	// * Discounts
	getDiscountWidget = async (uid: string, templateID: string) => this.legacyFetchWrap(`/discount/${uid}/${templateID}`);
	getDiscountWidgetPublic = async (uid: any, templateID: any, key: any) => this.legacyFetchWrap(`/discount/public/${uid}/${templateID}/${key}`);

	getDiscounts = async (uid: string) => this.legacyFetchWrap(`/discount/${uid}`);
	getDiscountGroups = async (uid: string) => this.legacyFetchWrap(`/discount/groups/${uid}`);
	getDiscountRedemptions = async (uid: string) => this.legacyFetchWrap(`/discountRedemptions/${uid}`);

	setDiscountTemplateOrderPriorities = async (uid: string, data: any) => this.legacyFetchWrap(`/discount/orderPriorities/${uid}`, 'POST', data);
	createDiscountGroup = async (uid: any, data: any) => (await this.legacyFetchWrap(`/discount/group/${uid}`, 'POST', data)).id;
	archiveDiscountGroup = async (uid: any, id: any, status: any) => this.legacyFetchWrap(`/discount/group/${uid}/${id}/${status}`, 'PUT');
	archiveDiscount = async (uid: any, discountID: any, status: any) => this.legacyFetchWrap(`/discount/${uid}/${discountID}/${status}`, 'PUT');

	// * Campaigns
	getCampaigns = async (uid: string, options?: { full?: boolean }) => this.legacyFetchWrap(`/campaigns/${uid}?full=${options?.full || ''}`);
	getCampaignGroups = async (uid: string) => this.legacyFetchWrap(`/campaign/groups/${uid}`);
	getSingleCampaign = async (uid: any, campaignID: any) => this.legacyFetchWrap(`/campaign/${uid}/${campaignID}`);
	archiveCampaign = async (uid: any, campaignID: any, status: any) => this.legacyFetchWrap(`/campaign/${uid}/${campaignID}/${status}`, 'PUT');
	archiveCampaignGroup = async (uid: any, gid: any, status: any) => this.legacyFetchWrap(`/campaign/group/${uid}/${gid}/${status}`, 'PUT');
	setCampaignActive = async (uid: string, campaignID: any, status: any) => this.legacyFetchWrap(`/campaign/status/${uid}/${campaignID}/${status}`, 'PUT');
	getPreviewSMSBody = async (body: any, uid: any) => this.legacyFetchWrap(`/campaign/preview/${uid}`, 'POST', { body });

	//* Sign up flow (freemium)
	// { token, email, password, name } : FreeSignupUser
	getInviteToken = async (token: string) => this.legacyFetchWrap(`/invite/token/${token}`);
	deleteInviteToken = async (token: any) => this.legacyFetchWrap(`/invite/token/${token}`, 'DELETE');
	createFreemiumUser = async (data: any) => this.legacyFetchWrap(`/invite/signup`, 'POST', data);

	// * Billing
	addBilling = async (
		name: string,
		zip: string,
		cardNumber: string,
		cvc: string,
		expMonth: string,
		expYear: string,
		uid: string | undefined,
		planID: number | undefined,
	) => this.legacyFetchWrap(`/billing/${uid}`, 'POST', { name, zip, cardNumber, cvc, expMonth, expYear, planID });
	getBillingSettings = async (uid: string) => this.legacyFetchWrap(`/users/fees/${uid}`);
	deleteBilling = async (uid: any) => this.legacyFetchWrap(`/billing/${uid}`, 'DELETE');
	deleteStarter = async (uid: any) => this.legacyFetchWrap(`/plan/${uid}`, 'DELETE');
	deleteBillingPaypal = async (uid: any) => this.legacyFetchWrap(`/paypal/${uid}`, 'DELETE');
	deleteBillingAmazon = async (uid: any) => this.legacyFetchWrap(`/amazon/${uid}`, 'DELETE');

	// * automations
	manageAutomations = async (uid: string, nostats: boolean) => await this.legacyFetchWrap(`/automations/${uid}${nostats ? '?block=true' : ''}`);
	getAutomation = async (uid: string, autoID: any) => this.legacyFetchWrap(`/automations/${uid}/${autoID}`);
	deleteAutomation = async (uid: any, automationID: any) => this.legacyFetchWrap(`/automations/${uid}/${automationID}`, 'DELETE');
	pauseAutomation = async (uid: any, automationID: any, newStatus: any) => this.legacyFetchWrap(`/automations/pause/${uid}/${automationID}`, 'PUT', { newStatus });
	editAutomation = async (uid: any) => this.legacyFetchWrap(`/stats/${uid}`, 'PUT');
	getAutomationStats = async (uid: any, autoID: any) => this.legacyFetchWrap(`/automations/stats/${uid}/${autoID}`);

	setImpliedConsent = async (uid: string, opts: ImpliedConsent) => await this.legacyFetchWrap(`/users/impliedConsent/${uid}`, 'PUT', opts);

	getMessagingStats = async (uid: string) => this.legacyFetchWrap(`/messaging/stats/${uid}`);
	getForecastPerOrderSMS = async (uid: string) => this.legacyFetchWrap(`/users/postSaleForecast/${uid}`);
	getPubStoreLocations = async (uid: any, config?: TokenWrappedConfig) => this.tokenWrapped(`/stores/public/${uid}`, config);
	getCustomAttributeTraits = async (uid: string) => this.legacyFetchWrap(`/customAttributes/${uid}`);
	getCustomPixelAttributeTraits = async (uid: string) => this.legacyFetchWrap(`/pixelAttributes/${uid}`);
	getProductAttributeTraits = async (uid: string) => this.legacyFetchWrap(`/productAttributes/${uid}`);

	// * brand products
	getProduct = async (uid: any, productId: any) => this.legacyFetchWrap(`/brand/product/${uid}/${productId}`);
	getProducts = async (uid: string, start?: number, limit = 20, search = '') =>
		this.legacyFetchWrap(`/brand/products/${uid}?archived?=true&start=${start}&limit=${limit}${search ? `&search=${search}` : ''}`);
	updateProduct = async (uid: string, product: any) => this.legacyFetchWrap(`/brand/product/${uid}`, 'POST', product);
	updateProducts = async (uid: any, products: any) => this.legacyFetchWrap(`/brand/products/${uid}`, 'POST', products);
	archiveProduct = async (uid: any, productId: any) => this.legacyFetchWrap(`/brand/product/${uid}/${productId}`, 'DELETE');

	downloadQRCodes = async (uid: any, productID: any, batchID: any) =>
		this.legacyFetchWrap(`/brand/qrCode/${uid}/${productID}/${batchID}`, 'GET', null, { asBlob: true });
	generateQRCodes = async (uid: any, data: any) => this.legacyFetchWrap(`/brand/qrCode/${uid}`, 'POST', data);

	getCollectibleData = async (uid: any, productID: any, batchID: any, collectibleID: any) =>
		this.legacyFetchWrap(`/brand/collectible/${uid}/${productID}/${batchID}/${collectibleID}`, 'GET');
	redeemCollectible = async (uid: any, productID: any, batchID: any, collectibleID: any, body: any) =>
		this.legacyFetchWrap(`/brand/collectible/redeem/${uid}/${productID}/${batchID}/${collectibleID}`, 'POST', body);
	reviewCollectibleData = async (uid: any, productID: any, batchID: any, collectibleID: any, body: any) =>
		this.legacyFetchWrap(`/brand/collectible/review/${uid}/${productID}/${batchID}/${collectibleID}`, 'POST', body);

	// * stores
	getAllStores = async (uid: string) => this.legacyFetchWrap(`/allStores/${uid}`);
	getAllAllowAds = async (uid: string) => this.legacyFetchWrap(`/allAllowAds/${uid}`);

	getStoreLocations = async (uid: string, distance = 50, fullState = false, config?: TokenWrappedConfig) =>
		this.tokenWrapped(`/stores/${uid}/${distance}?fullStateName=${fullState}`, config);
	addStore = async (uid: any, store: any) => this.legacyFetchWrap(`/stores/${uid}`, 'POST', store);
	editStore = async (storeID: any, uid: any, store: any) => this.legacyFetchWrap(`/stores/${uid}/${storeID}`, 'PUT', store);
	storeMappings = async (uid: any) => this.legacyFetchWrap(`/storeMappings/${uid}`);

	getThirdPartyStores = async (uid: string, getChannels?: boolean) => this.legacyFetchWrap(`/thirdPartyStores/${uid}?getChannels=${getChannels}`);
	createThirdPartyStore = async (uid: any, storeUID: any, storeID: any, store: any) =>
		this.legacyFetchWrap(`/thirdPartyStore/${uid}/${storeUID}/${storeID}`, 'POST', store);
	createThirdPartyStoresByID = async (uid: string, body: any[]) => this.legacyFetchWrap(`/thirdPartyStoresByID/${uid}`, 'POST', body);
	deleteThirdPartyStore = async (uid: any, storeUID: any, storeID: any) => this.legacyFetchWrap(`/thirdPartyStore/${uid}/${storeUID}/${storeID}`, 'DELETE');
	updateThirdPartyStore = async (uid: string, store: any) => this.legacyFetchWrap(`/thirdPartyStore/${uid}`, 'POST', store);
	batchUpdateThirdPartyStores = async (uid: any, stores: any) => this.legacyFetchWrap(`/thirdPartyStores/${uid}`, 'POST', stores);

	// * brand stuff
	getBrandInsights = async (uid: any, data: any, alias?: string) =>
		this.tokenWrapped(`/brand/insights/${uid}${alias ? `?alias=${alias}` : ''}`, { method: 'POST', payload: data });
	getBrandData = async (uid: any) => this.legacyFetchWrap(`/brand/data/${uid}`);
	setBrandAlias = async (uid: string, alias: any) => this.legacyFetchWrap(`/users/brandTargets/${uid}`, 'POST', alias);
	getBrandTemplate = async (uid: string) => this.legacyFetchWrap(`/brand/template/${uid}`);
	setBrandTemplate = async (uid: string, template: any) => this.legacyFetchWrap(`/brand/template/${uid}`, 'POST', template);
	addPendingStore = async (uid: any, name: any, street: any, city: any, state: any, country: any, notes: undefined) =>
		this.legacyFetchWrap(`/pendingStore/${uid}`, 'POST', {
			name,
			addr: { street, city, state, country },
			notes,
		});
	setBrandStoresForMA = async (uid: any, status: boolean) => this.legacyFetchWrap(`/users/brandStoresForMA/${uid}/${status}`, 'PUT');

	uploadContacts = async (uid: string, data: any) => this.legacyFetchWrap(`/users/files/${uid}`, 'POST', data);

	getPersonas = async (
		uid: string,
		options: {
			start?: number;
			limit?: number;
			search?: string;
			sort?: string;
			desc?: boolean;
			bypassOneCntOpt?: boolean;
			nameCheck?: boolean;
			init?: boolean;
		} = {},
		config?: TokenWrappedConfig,
	) => {
		const {
			start = 0,
			limit = 0,
			search = '',
			sort = 'signup',
			desc = false,
			bypassOneCntOpt = localStorage.getItem('bypassOneCntOpt') === 'true',
			nameCheck = false,
			init = false,
		} = options;
		return this.tokenWrapped(
			`/piis/${uid}?start=${start}&limit=${limit}&search=${encodeURIComponent(search || '')}&sort=${sort}&dir=${
				desc ? 'asc' : 'desc'
			}&bypassOneCntOpt=${bypassOneCntOpt}&nameCheck=${nameCheck}&init=${init}`,
			config,
		);
	};
	getPersonaTraits = async (uid: string, contactID: string, config?: TokenWrappedConfig) => this.tokenWrapped(`/piis/${uid}/${contactID}`, config);
	getLoyaltyPoints = async (uid: any, contactID: any, config?: TokenWrappedConfig) => this.tokenWrapped(`/contact/loyaltyPoints/${uid}/${contactID}`, config);
	getPersonaPixelEvents = async (uid: string, contactID: string) => this.legacyFetchWrap(`/contact/events/${uid}/${contactID}`);
	getPersonaOrders = async (uid: any, contactID: any) => this.legacyFetchWrap(`/contact/orders/${uid}/${contactID}`);

	getSources = async (uid: string, platform: string) => this.legacyFetchWrap(`/sources/${uid}/${platform}`); // platform can be "all" or a unique source code "greenbits"
	getDestinations = async (uid: string, platform: string) => this.legacyFetchWrap(`/destinations/${uid}/${platform}`); // platform can be "all" or a unique destination code "greenbits"
	getStateLaws = async (stateCode: any) => this.legacyFetchWrap(`/stateLaws/${stateCode}`);

	setIO = async (uid: any, status: any) => this.legacyFetchWrap(`/io/${uid}/${status}`, 'PUT');
	setMaxMTU = async (uid: any, maxMTU: any) => this.legacyFetchWrap(`/users/mtu/${uid}/${maxMTU}`, 'PUT');
	changeDefaultSMS = async (uid: any, data: any) => this.legacyFetchWrap(`/users/optinMsg/${uid}`, 'PUT', data);
	changeDefaultMessageNotice = async (uid: any, message: any) => this.legacyFetchWrap(`/users/msgContainer/${uid}`, 'PUT', { msgContainer: message });
	changeWalletLoginMessage = async (uid: any, message: any) => this.legacyFetchWrap(`/users/walletLoginMessage/${uid}`, 'PUT', { walletLoginMessage: message });
	changePostOptinSMS = async (uid: any, message: any) => this.legacyFetchWrap(`/users/postOptinMsg/${uid}`, 'PUT', { postOptinMessage: message });
	changePointsBlacklist = async (uid: any, blacklist: any) => this.legacyFetchWrap(`/users/pointsBlacklist/${uid}`, 'PUT', { pointsBlacklist: blacklist });
	changePerOrderSMS = async (uid: any, message: any) => this.legacyFetchWrap(`/users/postSaleMsg/${uid}`, 'PUT', { postSaleMessage: message });
	changePointsAccrualDate = async (uid: string, cutoff: number) => this.legacyFetchWrap(`/users/loyaltyCutoff/${uid}/${cutoff}`, 'PUT', { cutoff: cutoff });

	dashData = async () => this.legacyFetchWrap(`/dashData`);
	brands = async () => this.legacyFetchWrap(`/brands`, 'GET', null, { noxhost: true });
	deleteUser = async (uid: any) => this.legacyFetchWrap(`/users/${uid}`, 'DELETE');
	queueLookup = async (username: any, recipients: any) => this.legacyFetchWrap(`/lookup`, 'POST', { username, recipients });
	pendingLookups = async () => this.legacyFetchWrap(`/lookup`);

	//* CSM / Account health dash
	getCSMInfo = async () => this.legacyFetchWrap(`/accountHealth/csms`);
	getTickets = async (uid: string) => this.legacyFetchWrap(`/users/tickets/${uid}`);
	getCSMCompanies = async (uid: string) => this.legacyFetchWrap(`/accountHealth/companies?uid=${uid}`);
	getCSMTickets = async (uid: string) => this.legacyFetchWrap(`/accountHealth/tickets?email=${this.getEmail()}&uid=${uid}`);
	getCurrentCSM = async () => this.legacyFetchWrap(`/accountHealth/csms?email=${this.getEmail()}`);

	updateAccountMessage = async (uid: string, id: string, message: AccountMessage) => this.legacyFetchWrap(`/accountHealth/messages/${uid}/${id}`, 'PUT', message);
	createAccountMessage = async (uid: string, message: AccountMessage) => this.legacyFetchWrap(`/accountHealth/messages/${uid}`, 'POST', message);
	getAccountMessage = async (uid: string, id: string) => this.legacyFetchWrap(`/accountHealth/messages/${uid}/${id}`);
	getAccountMessages = async (uid: string) => this.legacyFetchWrap(`/accountHealth/messages/${uid}`);
	getImporterStatuses = async (uid: string) => this.legacyFetchWrap(`/debug/importer/status/${uid}`);

	getProductDiscovery = async (uid: string, data: any, signal = null) => this.legacyFetchWrap(`/product/discovery/${uid}`, 'POST', data, null, signal);
	getBrandProductConsumption = async (uid: any) => this.legacyFetchWrap(`/brand/productConsumption/${uid}`);
	getProductConsumption = async (uid: any) => this.legacyFetchWrap(`/productConsumption/${uid}`);

	addToMergeBlacklist = async (uid: string, blacklist: any) => {
		// add endpoint
	};

	ignoreDupeField = async (uid: string, value: any) => {
		// add endpoint
	};

	//* Birchmount Giftcards
	updateBirchmount = async (uid: string, cntKey: string, payload: any) => this.legacyFetchWrap(`/contact/birchmountCard/${uid}`, 'POST', { cntKey, ...payload });

	// blockStats = async (uid: any) => this.fetch(`/block/stats/${uid}?users=10`);
	// getPendingStoreLocations = async (uid) => this.fetch(`/pendingStore/${uid}/50`);
	// getStores = async (stateCode) => this.fetch(`/stateLaws/${stateCode}`);
	// getStore = async (stateCode) => this.fetch(`/stateLaws/${stateCode}`);
	// getAgencyStats = async (uid) => this.fetch(`/insights/${uid}`);
	// getAllTimeStats = async () => this.fetch(`/alltime`);

	// setLimits = async (uid, instaFollows, instaLikes, instaComments, instaMsgs, twFollows, twLikes, twComments, twMsgs) => {
	// 	return this.fetch(`/limits/${uid}`, 'PUT', {
	// 		instagram: {
	// 			follows: instaFollows,
	// 			likes: instaLikes,
	// 			comments: instaComments,
	// 			msgs: instaMsgs
	// 		},
	// 		twitter: {
	// 			follows: twFollows,
	// 			likes: twLikes,
	// 			comments: twComments,
	// 			msgs: twMsgs
	// 		}
	// 	});
	// };

	// getInbox = async (uid, filters) => {
	// 	let q = '';
	// 	for (const key of Object.keys(filters || {})) {
	// 		q = `${q}${key}=${encodeURIComponent(filters[key])}&`;
	// 	}
	// 	return this.fetch(`/inbox/${uid}?${q}`);
	// };

	loggedIn() {
		// Checks if there is a saved token and it's still valid
		const token = this.getToken(); // GEtting token from localstorage
		return !!token && !this.isTokenExpired(token); // handwaiving here
	}

	findLogin = async (email: string) => this.legacyFetchWrap(`/users/login/${encodeURIComponent(email)}`);

	isTokenExpired(token: string) {
		try {
			const decoded = decode(token);
			// @ts-ignore
			if (decoded.exp < Date.now() / 1000) {
				// Checking if token is expired. N
				return true;
			} else return false;
		} catch (err) {
			return false;
		}
	}

	setToken(idToken: string) {
		// Saves user token to localStorage
		try {
			localStorage.setItem('alpineToken', idToken);
		} catch (_) {
			utils.appendQueryParam('alpineToken', idToken);
			(window as any)['alpineToken'] = idToken;
		}
	}

	setUID(uid: string) {
		try {
			localStorage.setItem('alpineUID', uid);
		} catch (_) {
			utils.appendQueryParam('alpineUID', uid);
			(window as any)['alpineUID'] = uid;
		}
	}

	setGlobalID(globalID: string) {
		try {
			localStorage.setItem('alpineGlobalID', globalID);
		} catch (_) {
			utils.appendQueryParam('alpineGlobalID', globalID);
			(window as any)['alpineGlobalID'] = globalID;
		}
	}

	setRole(role: string) {
		try {
			localStorage.setItem('alpineRole', role);
		} catch (_) {
			utils.appendQueryParam('alpineRole', role);
			(window as any)['alpineRole'] = role;
		}
	}

	setEmail(email: string) {
		try {
			localStorage.setItem('alpineEmail', email);
		} catch (_) {
			utils.appendQueryParam('alpineEmail', email);
			(window as any)['alpineEmail'] = email;
		}
	}

	getToken() {
		const token = utils.queryParam('alpineToken');
		if (token) return token;
		try {
			return localStorage.getItem('alpineToken');
		} catch (err) {
			utils.appendQueryParam('noLS', true);
			return (window as any)['alpineToken'];
		}
	}

	getUID() {
		const uid = utils.queryParam('alpineUID');
		if (uid) return uid;

		try {
			return localStorage.getItem('alpineUID');
		} catch (_) {
			return (window as any)['alpineUID'];
		}
	}

	getGlobalID() {
		const globalID = utils.queryParam('alpineGlobalID');
		if (globalID && globalID !== 'undefined') return globalID;

		try {
			return JSON.parse(localStorage.getItem('alpineGlobalID') || '');
		} catch (_) {
			return (window as any)['alpineGlobalID'];
		}
	}

	getRole(): Role | undefined {
		const token = utils.queryParam('alpineRole');
		if (token) return token || undefined;
		try {
			return (localStorage.getItem('alpineRole') as Role) || undefined;
		} catch (err) {
			utils.appendQueryParam('noLS', true);
			return (window as any)['alpineRole'] || undefined;
		}
	}

	isSuperAdmin(role: string) {
		role = role || this.getRole();
		return role === Role.ADMIN_SUPERUSER;
	}

	getEmail() {
		const email = utils.queryParam('alpineEmail');
		if (email) return email;

		try {
			return localStorage.getItem('alpineEmail');
		} catch (_) {
			return (window as any)['alpineEmail'];
		}
	}

	isAdmin = (role?: Role) => (role || this.getRole() || '').startsWith('admin');
	hasRole = (role: Role) => utils.auth.isAdmin() || this.getRole() === role;

	async getPDF(uid?: string, state?: any, url?: string) {
		if (!url) {
			url = location.protocol + '//' + location.host + '/public' + location.pathname;
		}
		const tok = 'alpineToken=' + this.getToken() + '&';
		const data = await this.legacyFetchWrap(
			`/pdf/${uid}`,
			'POST',
			{ url: ((url || '').includes('?') ? url + '&' : url + '?') + tok, state: state },
			{ asBlob: true },
		);
		const a = document.createElement('a');
		a.href = window.URL.createObjectURL(data);
		a.setAttribute('download', 'report.pdf');
		a.click();
		// return false;
		// window.location.assign(window.URL.createObjectURL(data))
	}

	getStoresByBrandName = async (uid: string, includeAlias: any, data: any) =>
		this.legacyFetchWrap(`/storesByBrandName/${uid}?inclAlias=${includeAlias}`, 'POST', data);

	async logout() {
		// eslint-disable-next-line no-empty
		try {
			await utils.auth.post('logout/' + this.getUID());
		} catch {}
		// Clear user token and profile data from localStorage
		try {
			localStorage.removeItem('alpineToken');
			localStorage.removeItem('alpineRole');
			localStorage.removeItem('alpineEmail');
			localStorage.removeItem('alpineUID');
			localStorage.removeItem('alpineGlobalID');
		} catch (_) {
			(window as any)['alpineUID'] = (window as any)['alpineToken'] = undefined;
		}
		utils.appendQueryParam('alpineUID', null);
		utils.appendQueryParam('alpineToken', null);
		// @ts-ignore
		utils.user = null;
		utils.asHost = '';
		utils.uid = '';
		utils.realID = '';
		return;
	}

	// Using jwt-decode npm package to decode the token
	getProfile = () => decode(this.getToken());

	get = async (ep: string, opts?: any) => this.tokenWrapped(`/${ep}`, { opts, token: opts?.token });
	getMulti = async (...eps: (string | null)[]) => Promise.all(eps.map((ep) => (typeof ep !== 'string' ? ep : this.get(ep))));
	put = async (ep: string, payload?: any, opts?: any) => this.tokenWrapped(`/${ep.replace(/^\//, '')}`, { ...opts, method: 'PUT', payload });
	post = async (ep: string, payload?: any, opts?: any) => this.tokenWrapped(`/${ep.replace(/^\//, '')}`, { ...opts, method: 'POST', payload });
	delete = async (ep: string, payload?: any) => this.legacyFetchWrap(`/${ep.replace(/^\//, '')}`, 'DELETE', payload);

	/**
	 * @deprecated This function is used only to wrap super old code and shouldn't be used going forward.
	 */
	legacyFetchWrap = (url: string, method = 'GET', payload?: any, opts?: any, signal?: any, forceToken?: string) => {
		return this.legacyFetch(url, { method, payload, opts, signal, forceToken });
	};

	/**
	 * @deprecated Replaced by fetchAPI in api/index.ts
	 */
	legacyFetch = async (url: string, options: FetchOptions) => {
		const defaultOptions: FetchOptions = {
			timeout: undefined,
			retryDelay: 1000,
			enableCaching: false,
			...options?.opts,
		};

		options = { ...defaultOptions, ...options, ...options?.opts };

		// if url starts with a /, add /api/v1
		if (url.startsWith('/') && !url.startsWith('/api/v1')) {
			if (options.forceToken) {
				url = url.replace(':uid', options?.uid || (utils.isLocal() || utils.isStaging() ? utils.TEST_UID : undefined) || utils.uid);
			}
			url = `${utils.apiDomain()}/api/v1/${url}`;
		}

		// replace double slashes.. unless it's http:// or https://
		url = url.replace(':uid', options?.uid || utils.uid).replace(/([^:]\/)\/+/g, '$1');

		if (userUtils.isDebugging('legacyFetch') || utils.isVercelPreview()) console.log('fetching', url, options);

		const req: RequestInit | any = {
			body: null,
			method: options.method || 'GET',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
			cache: options.enableCaching ? 'default' : 'no-cache',
			...options,
		};

		if (options.signal) {
			req.signal = options.signal;
		}
		if (this.loggedIn()) {
			req.headers['Authorization'] = (utils.isLocal() || utils.isStaging() ? options.forceToken : undefined) || this.getToken();
		}
		if (utils.asHost && (!options || !options.noxhost)) {
			req.headers['X-Host'] = utils.asHost;
		}

		if (options.payload instanceof FormData || options.payload instanceof Blob) {
			req.body = options.payload;
			delete req.headers['Content-Type'];
		} else if (typeof options.payload === 'string') {
			req.body = options.payload;
		} else if (typeof options.payload === 'function') {
			req.body = options.payload(req);
		} else if (options.payload != null) {
			req.body = JSON.stringify(options.payload);
		}

		const fetchWithRetries = async (): Promise<any> => {
			let retriesLeft = options?.retries || 0;
			let delay = options?.retryDelay || 0;
			let lastError: Error | undefined;
			let response: Response;

			do {
				try {
					response = await fetch(url, req);

					if (!response.ok) {
						throw new Error(response.statusText);
					}

					if (!options?.asBlob) {
						const data = await response.json();
						return data;
					} else {
						const data = await (options?.asBlob ? response.blob() : response.text());
						return data || response.statusText;
					}
				} catch (error) {
					lastError = error as Error;
					retriesLeft--;

					if (retriesLeft >= 0) {
						await new Promise((resolve) => setTimeout(resolve, delay));
						delay *= 2;
					}
				}
			} while (retriesLeft >= 0);

			// If we've made it here, all retries have failed
			throw lastError || new Error('All retries failed');
		};

		let response: any = undefined;
		if (options.timeout || options.retries) {
			const promises = [fetchWithRetries()];
			if (options.timeout) {
				promises.push(new Promise((_, reject) => setTimeout(() => reject(new Error('Request timeout')), options?.timeout)));
			}
			response = await Promise.race(promises);
		} else response = await fetch(url, req);

		if (!response && options.timeout) {
			throw new Error('Request timeout');
		}

		const ct = response.headers?.get('Content-Type');
		const isJSON = !!ct && ct.startsWith('application/json');

		if (!isJSON || options.asBlob) {
			const data = (await (options.asBlob ? response.blob() : response.text())) || response.statusText;
			if (response.status > 299) {
				throw new Error(data);
			}
			return data;
		}
		let apiResp = await response.json();
		if (!apiResp) {
			throw new Error((await response.text()) || response.statusText);
		}

		if (utils.isStaging()) {
			apiResp = JSON.parse(JSON.stringify(apiResp ?? {})?.replaceAll('cdn.aiqstaging', 'lab.aiqstaging') ?? '');
		}

		if (Array.isArray(apiResp.errors)) {
			const errorMessages = apiResp.errors
				.map((err: { message: any }) => ('message' in err ? err.message : err))
				.filter((m: any) => !!m)
				.join('\n');
			throw new Error(errorMessages);
		}
		return options.fullResponse ? apiResp : 'data' in apiResp ? apiResp.data : apiResp;
	};
}
