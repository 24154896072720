import posthog from 'posthog-js';
import utils from './utils';

export function executeIfPosthog(callback: () => void): void {
	if (canLoadPosthog()) {
		callback();
	}
}

// For testing posthog is only loaded on the dashboard some pages/paths will be disabled
export function canLoadPosthog(): boolean {
	// Temporailty disable for non-logged in users
	if (!utils.auth.loggedIn()) {
		return false;
	}

	const ignorePages: string[] = [];
	if (ignorePages.some((path) => location.pathname.toLowerCase().includes(path))) {
		return false;
	}

	const isNonShortyDashboard = utils.isAlpineMain() || utils.isDevEnv();
	return isNonShortyDashboard;
}

export function capturePosthogEvent(event: string, properties?: Record<string, any>): void {
	executeIfPosthog(() => {
		posthog.capture(event, properties);
	});
}
